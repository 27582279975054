@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Baloo+Bhaijaan:400,700|Exo+2:400,700");

// **********************************
//  overwrites
// **********************************

$avado-blue: #2e4e72;
$avado-lightblue: #4ad9df;
$avado-darkblue: #183352;

$primary: $avado-blue;
$body-family: 'Baloo Bhaijaan', sans-serif;

$button-border-width: 3px;
$button-color: blue;
$button-padding-vertical: 0.75em;

p {
    font-weight: 100; }

// .ryo-navbar
//     padding: 4em 2em 1em 2em
//     background: $primary

.ryo-container {
    padding: 1.5em 2em 1em 2.5em;
    background: $primary;
    color: white; }


.ryo-container h2 {
    font-size: 2em;
    font-family: 'Baloo Bhaijaan', sans-serif; }


.ryo-container .content {
    color: white;
    font-weight: 100;
    font-size: 1.15em;
    width: 100%;
    max-width: 1000px; }

.ryo-container .title {
    color: white; }

.ryo-container .subtitle {
    font-size: 1.5em;
    color: #aaa; }

// .warning
//     width: 100%
//     max-width: 1000px
//     box-sizing:border-box
//     padding: 1em
//     border: 2px solid rgba(255,255,255,0.2)
//     font-family: 'Baloo Bhaijaan', sans-serif


.endpoint-box {
    margin: 4em 0;
    padding: 2em;
    background: $avado-darkblue;
    border-radius: 10px;
    color: white; }

.endpoint-box h1 {
    color: white; }
//     // opacity: 0.3

// .endpoint-box p
//     color: white
//     font-size: 1.5em
//     font-family: 'Baloo Bhaijaan', sans-serif


@import "../../node_modules/bulma/bulma.sass";
@import '../../node_modules/bulma-slider/src/sass/index.sass';

.App {
    font-family: 'Exo 2', sans-serif; }
